.alarm-card-header
  justify-content: center
  margin-bottom: 8px
  margin-top: 10px

  &-title
    font-size: 16px
    font-weight: bold
    padding-right: 2px
    padding-bottom: 4px
    text-align: left
    overflow: hidden
    text-overflow: ellipsis
    min-height: 48px
    max-height: 48px

  &-status
    margin-left: auto
    margin-right: 0
    align-self: center
    width: fit-content
    height: fit-content
    text-align: center
    font-weight: bolder
    background-color: gray
    border-radius: 4px
    padding: 2px 8px 4px 6px
