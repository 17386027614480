.copy-button-container
      position: relative
      display: flex
      justify-content: right
      align-items: center


.copy-button
    visibility: hidden


.copy-button-container:hover .copy-button
    visibility: visible


.pump-info-panel__attributes_value
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

