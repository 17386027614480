.alarm-card
  border-radius: 6px
  background-color: lightgray
  margin: 6px 2px
  padding: 6px 16px 6px 16px
  overflow: hidden
  width: 320px
  &-actions
    margin-top: 8px

.status-switch
  width: 100%