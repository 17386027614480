.pump-picture-modal 
  box-sizing: border-box
  width: 100vw
  height: 100vh
  padding: 32px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center


  &__content 
    gap: 2px
    display: flex
    flex-direction: column
    overflow: auto


  &__header 
    background-color: #e0ebf3
    display: flex
    align-items: center
    padding: 2px 8px 0 4px
    height: 44px
    border: 1px solid #d4dee6
    color: var(--color-ksb-blue)
    font-weight: 600
    border-radius: 6px 6px 0 0
    box-sizing: border-box
    border-bottom: 0


  &__header__spacer 
    flex: 1 1 0%


  &__image 
    max-width: 95vh
    max-height: 95vh
    min-height: 0
    object-fit: contain
    border-radius: 0 0 6px 6px

