.App
    text-align: center
    --strong-blue: #00569d
    --light-blue: #e0ebf3
    --grey: #8e8e8d

// .App-header
//     background-color: white
//     min-height: 100vh
//     display: flex
//     flex-direction: column
//     align-items: center
//     justify-content: center
//     font-size: calc(10px + 2vmin)
//     color: white

// :root
