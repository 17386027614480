.tag
    display: flex
    border: 1px solid var(--strong-blue)
    padding: 0 10px
    border-radius: 5px
    background-color: var(--light-blue)
    color: var(--strong-blue)

    &:first-child
        margin-left: 0

    &-list
        display: flex
        justify-content: end
        flex-wrap: wrap
        gap: 5px


    &__remove-button
        padding: 0 !important
        padding-left: 10px !important

.highlighted
    background-color: yellow

.table-entry-failure-status
    max-width: 200px
    float: left
    overflow: hidden
    text-overflow: ellipsis
    text-align: left
    text-wrap: nowrap
    margin-top: 4px
    margin-right: 4px
    background-color: gray
    border-radius: 4px
    padding: 2px 6px

.reset-filters-button
    margin-left: auto