.highlighted
    background-color: yellow

.failure-state-box
    float: left
    overflow: hidden
    text-overflow: ellipsis
    text-align: left
    text-wrap: nowrap
    margin-top: 4px
    margin-right: 4px
    background-color: gray
    border-radius: 4px
    padding: 2px 6px