.urls-list

    &__input-table
        margin-top: 20px
        td
            vertical-align: top

        &-header
            font-weight: bold
            color: var(--strong-blue)
            font-size: 14px
            padding-right: 20px
            padding-bottom: 10px

    &__input input
        padding: 0 !important
        font-size: 14px
        padding-left: 10px !important

    &__input-td
        width: 100%
        padding-right: 20px
