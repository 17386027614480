.case-details
  margin-top: 20px
  margin-left: 20px
  margin-right: 20px

.common-item-list

  p
    margin: 0 0 0 10px

  &__item
    margin: 0

  &__description
    border-top: none
    padding-bottom: 0
    white-space: pre-wrap

  &--bold
    font-weight: bold
    margin-top:  2px

  &__date
    margin-left: 10px
    font-size: 14px
    &-edited
      color: grey
      font-style: italic

  &__title
    font-size: 16px

  &__author
    font-size: 17px