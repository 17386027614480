
.alarm-card
  &-detail
    line-height: 1rem
    padding: 5px 0
    &-key
      font-size: 14px
      text-align: left
      font-weight: lighter
      display: inline-block
    &-value
      font-size: 15px
      text-align: right
      font-weight: bolder
      display: inline-block
      float: right



