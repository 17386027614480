.pump-image
  display: flex
  justify-content: center
  height: 200px
  max-width: 310px
  margin: 8px

  &___img
    border-radius: 6px
    overflow: hidden
    cursor: pointer
    object-fit: cover
    display: block
    width: 100%
    height: 100%