.comment-author
  font-weight: bold
  font-size: 16px

.comment-date
  margin-left: 8px
  font-size: 14px

.comment-value
  font-size: 16px
  margin: 0
  white-space: pre-line