.App-header {
  background-color: white;
}

.App-header header {
  background-color: white;
  border-bottom: 2px solid var(--light-blue);
  box-shadow: none;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
}

.main > * {
  width: 100%;
  text-align: left;
}

.title {
  margin: 20px 0;
}

.menu-title {
  text-align: left;
  display: flex;
  color: var(--strong-blue);
  align-items: center;
}