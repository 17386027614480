.history-entry
  margin-bottom: 8px

.history-entry-author
  font-weight: bold
  font-size: 16px

.history-entry-date
  margin-left: 8px
  font-weight: normal
  font-size: 14px

.history-entry-value
  font-size: 16px
  margin: 0
  font-weight: normal