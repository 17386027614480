.pump-info-panel 
    display: flex
    flex-direction: column
    gap: 2px
    width: 24%
    min-width: 380px
    max-width: 460px
    padding-left: 10px
    padding-bottom: 8px

    &__toggles
        padding-bottom: 10px
        display: flex
        flex-direction: row

    &__attributes
        &_value
            font-weight: 460
        &_name
            color: gray
            font-size: 14px
            margin-top: -6px
            margin-bottom: -6px
