.pump-image-placeholder
    background-color: #f6f6f6
    display: flex
    justify-content: center
    align-items: center
    height: 200px
    width: 360px
    margin-top: 20px
    margin-bottom: 20px
    border-radius: 6px

    &___img
        height: 100%
